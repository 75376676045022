
body .flexlayout__tab_toolbar {
  margin-right: 30px;
  padding-left: 0px;
  z-index: 12;
  position: relative;
  background-color: inherit;
}

body .flexlayout__tab_toolbar_button-float {
  display: none;
}
.flexlayout__tab_button {
  max-width: calc(100% - 150px);
  z-index: 9;
}
.flexlayout__tab_button:last-of-type {
  margin-right:100px;
}

.flexlayout__tab_button--selected:hover {
  z-index: 17;
}
.flexlayout__tabset_tabbar_inner_tab_container  {
  width: 100% !important;
}
body .flexlayout__tabset_tabbar_inner {
  position: relative;
  z-index: 10;
  overflow: visible;

}
body .flexlayout__tabset_tabbar_inner:has(.flexlayout__tab_button--selected:hover) {
  z-index: 17;
}
body .flexlayout__tab {
  background-color: var(--dock-background);
}
.flexlayout__tab_button_content {
  display: flex;
  text-wrap: nowrap;
  max-width: calc(120% - 30px);
  text-overflow: ellipsis;
  overflow: hidden;
}
