.tabs {
    overflow-y: hidden;
    .nav-tabs {
        display: block;
        table-layout: fixed;
        border: none;
        width: 100%;

        .tabs__link{
            padding-right: 3.75px !important;
            padding-left: 3.75px !important;
        }

        &.narrow {
            .active {
                width: 7.125rem;
            }
        }
    }

    &__actions {
        position: fixed;
        top: 6px;
        z-index: 15;
        width: 75px;
        display: flex;
        justify-content: flex-end;
        height: 32px;
        padding-top: 7px;
        background-color: transparent;
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;

            li {
                cursor: pointer;
                margin-left: 1.5px;
                float: right;
            }
        }

    }

    .tab-content {
        position: absolute;
        left: 0;
        height: 99.9%;
        padding: 0 $padding-tile;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
    }
}

.nav-item, ul[role=tablist] {
    li {
        font-weight: bold;
        margin: 0 6px 0 0;
    }
    // display: table;
    // table-layout: fixed;
    border: none;
}

ul[role=tablist] {
    width: 100%;
}

li[role=presentation] {
    text-align: center;
}

.nav-item, li[role=presentation] {
    display: inline-block;
    border-bottom: var(--list-border);
    color: var(--grey-font-color);

    :hover{
        text-decoration: none;
    }
    display: inline-block;
    padding: 0px 15px;

    &:not(:last-child) {
        border-right: none;
    }
    a {
        font-weight: 400;
        color: var(--default-font-color);
    }
    &.active {
        color: var(--default-font-color);
        border-bottom: none;
        a {
            color: var(--default-font-color);
        }
        .tabs__actions {
            display: inline-flex;
        }
        font-weight: bold;
    }

    &.inactive, &:not(.active) {
        color: var(--grey-font-color);
        a {
            color:var(--default-font-color);
        }
    }

    &:first-child {
        .nav-link {
            border-left: 0;
            padding-left: 0;
        }
    }

    .nav-link {
        display: block;
        padding: $padding-default;
        border: none;
        background-color: transparent;
        &:hover,
        &:focus {
            border: none;

        }

        .justify-content-between {
            overflow: hidden;
        }

    }
}
.create-price-alarm {
    margin-bottom: 2px;
    color: white;
}
.sidebar__recent-actions {
    .nav-item, li[role=presentation] {

        padding: 0px 5px;
        h3 {
            font-size: $font-size-normal;
        }
    }
}
.flexlayout__tab .tabs {
    .tab-title h3, .nav-tabs {
        display: block;
        height: 1px;
    }
    z-index: 15;
}
