.uitable {
  -webkit-font-smoothing: antialiased;
  height: 100%;
  position: relative;

  table {
    min-width: 0 !important;
    color: $grey-charcoal;
    font-size: $font-size-normal;
    margin-bottom: $actions-bottom-height !important;
    border-collapse: separate;
    border-spacing: 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    height: 100%;
    color: var(--default-font-color);

    &.row-table {
      width: 100%;
      margin: 0 !important;
      background-color: transparent;

      tbody {
        tr {
          display: table;
          table-layout: fixed;
          width: 100%;
          td:first-child {
            text-align: center;
          }
        }
        tr:nth-of-type(odd) {
          background-color: var(--table-odd-background);
        }
        tr:nth-of-type(even) {
          background-color: var(--table-even-background);
        }
      }
    }

    thead th {
      border-bottom: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 0;
      padding-right: 0;
      font-weight: bold;
      border-top: none;
      overflow: hidden;
      &:first-child {
        padding-left: $padding-default;
      }

      &:last-child {
        padding-right: $padding-default;
      }

      .oi-arrow-thick-bottom,
      .oi-arrow-thick-top {
        color: var(--default-font-color);
      }
    }

    tbody.pinnedTable.scrollable,
    tbody.unpinnedTable.scrollable {
      width: 100%;

      display: flex;
      flex-direction: column;
    }

    tbody.pinnedTable.scrollable {
      max-height: 50%;
      overflow-y: scroll;
      filter: brightness(80%);
      border-bottom: 2px solid $primary-color;
      border-top: none;
    }

    tbody.unpinnedTable.scrollable {
      flex: 1;
      overflow-y: auto;
    }

    tbody {
      border-top: none;
      transform: translateZ(0);
      tr {
        display: table;
        table-layout: fixed;
        width: 100%;
        cursor: pointer;
        overflow-x: hidden;

        td {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: $column-padding;
          border-top: 0;
          overflow: hidden;
          &:first-child {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            padding-left: $padding-default;
          }

          &:last-child {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            padding-right: $padding-default;
          }
        }
      }
    }
  }

  &.hidden-headlines {
    table thead,
    .empty-row {
      display: none;
    }
  }

  .search {
    position: absolute;
    top: 30px;
    left: 7.5px;
    width: calc(100% - 15px);
    background: var(--table-odd-background);
  }
  .ag-layout-normal {
    overflow-y: auto;
  }
  .table-visibility-filter {
    position: absolute;
    top: 0px;
    right: 15px;
    width: calc(100% - 30px);
    background-color: var(--table-odd-background);
    color: var(--default-font-color);
    border-radius: $border-radius;
    z-index: 999;
    overflow: auto;
    max-width: 300px;

    .form-check {
      padding-left: 0;
    }

    &.hidden {
      display: none;
    }

    .row {
      margin: 0;
    }
  }
}

.table-custom-border .orderbook-expiry-row {
  border-bottom: 0.75px solid var(--border-color-background);
}
.table-custom-border .not-interactive {
  border-bottom: none;
}

.default-border {
  border-bottom: 2px solid var(--border-color-background) !important;
}
.group-border {
  border-top: 2px solid var(--dock-background) !important;
}
.block-border {
  border-right: 4px solid var(--dock-background) !important;
}

.expiry-column {
  background-color: var(--expiry-table-bg);
}

.ag-center-cols-container {
  background-color: var(--table-odd-background);
}

.ag-cell {

  &.price-column {
    text-align: right;
  }

  &.quantity-column {
    text-align: right;
  }

  &.setlPrice-column {
    text-align: right;
  }

  &.execPrice-column {
    text-align: right;
  }

  &.counterparty-column {
    text-align: right;
  }

  &.timestamp-column {
    text-align: right;
  }

  &.date-column {
    text-align: right;
  }

  &.paidGiven-column {
    text-align: right;
  }

  &.not-interactive {
    background-color: var(
                    --not-interactive-background
    ) !important;
  }

  &.odd-table {
    background-color: var(--table-odd-background)
  }

  &.even-table {
    background-color: var(--table-even-background)
  }

  .inside-info {
    background-color: var(
                    --inside-informations-background
    );
  }
}

.PriceAlarm {
  .ag-cell {
    &.expiry-column {
      background-color: transparent;
    }
  }
}

.ag-cell-container {
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1;

  .main-layer {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;


  }

  .label {
    z-index: 99999;
    width: 100%;
    height: 100%;
    padding: 0px 10px;
    position: absolute;

    &.combined {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &.suspended {
      background-color: var(--order-suspended-background);
    }

    &.readonly {
      background-color: var(--order-readonly-background);
    }

    &.own-order {
      background-color: var(--order-own-background);
    }

    &.non-own-order {
      background-color: var(--order-background);
    }

    &.quote-request {
      background-color: var(--quote-request-background);
    }

    &.preview-order {
      background-color: var(--order-preview-background);
    }

    &.implied {
      background-color: var(--implied-background);
    }
    &.periodType-quarter_hour {
      background-color: var(--quarter_hour-background);
    }

    &.periodType-half_hour {
      background-color: var(--half_hour-background);
    }

    &.periodType-hour {
      background-color: var(--hour-background);
    }

    &.periodType-block_2_h {
      background-color: var(--block_2_h-background);
    }

    &.periodType-block_4_h {
      background-color: var(--block_4_h-background);
    }
    &.routed {
      background-color: var(--routed-background);
    }
  }
}

.own-order {
  background-color: var(--order-own-background);
}

.remove-table-button {
  border: none;
  outline: none;
  background-color: transparent;
}

.non-own-order {
  background-color: var(--order-background);
}

.column-header {
  background-color: var(--dock-background);
  --ag-header-cell-hover-background-color: var(--dock-background);
}

.order-font {
  color: var(--order-font);
}

.order-own-font {
  color: var(--order-own-font);
}

.read-only-font {
  color: var(--order-readonly-font);
}

.order-suspended-font {
  color: var(--order-suspended-font);
}

.quote-request-font {
  color: var(--quote-request-font);
}

.routed-font {
  color: var(--routed-font);
}

.implied-font {
  color: var(--implied-font);
}

.counterparty-eex-font {
  color: var(--counterparty-eex-font);
}

.counterparty-eexi-font {
  color: var(--counterparty-eexi-font);
}

.counterparty-gfi-font {
  color: var(--counterparty-gfi-font);
}

.cell-updated {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color:  var(--order-highlight-background) !important;
  animation-name: cell-highlight;
  animation-duration: 5s;
}

@keyframes cell-highlight {
  from {
    background-color: var(--order-highlight-background) !important;
  }

  to {
    background-color: transparent;
  }
}

.group-wrapper {
  z-index: 2;

  .market-instrument-column-expiry {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    height: fit-content;
    justify-content: space-between;

    &.minus {
      justify-content: end;
    }

    &.plus {
      justify-content: end;
    }

    button {
      line-height: 130%;
      background-color: transparent;
      border: none;
      outline: none;
      height: fit-content;
    }
  }
}

tr.implied-table.implied td {
  color: var(--implied-font);
}
tr td {
  &.implied-contract {
    width: 270px;
  }
}

.orderbook {
  position: relative;
  height: 100%;
  width: 100%;

  .books-view {
    display: block;
    width: 100%;
    height: 100%;

    &.grid-wrapper {
      width: 100%;
      height: 100%;

      .grid-container {
        height: 100%;
        width: 100%;

        .main-tabel-wrapper {
          display: flex;
          flex-direction: column;
          height: 100%;

          .table {
            position: relative;
            width: 100%;
            height: 100%;
            margin-bottom: 0px;

            &.ag-theme-quartz-dark {
              --ag-value-change-value-highlight-background-color: var(--order-highlight-background) !important;
              --ag-wrapper-border-radius: 0px;
              --ag-background-color: transperent;
              --ag-selected-row-background-color: transperent;
              --ag-active-color: transperent;
              --ag-header-background-color: var(--marketsheet-headers);
              --ag-font-size: 14px;
            }
          }
        }
      }
    }
  }

  .expiry,
  .instrument {
    display: table-cell;
    tbody.empty-row tr td {
      height: 6px;
      font-size: 0px;
    }

    &-header {
      display: flex !important;
      position: sticky;
      top: 0px;
      padding: 0 0;
      z-index: 11;
      height: 30px;
      text-transform: uppercase;
      background: var(--dock-background);
      color: var(--default-font-color);
    }
  }

  .instrument {
    padding: 0px 6px 0px 0px;
    &-header {
      h3 {
        padding-left: $padding-default;
        white-space: nowrap;
        display: block;
        position: relative;
        bottom: 5px;
        height: 16px;
        margin-top: unset;
        position: absolute;
        width: 100%;
        .oi {
          margin-right: 5px;
          float: right;
          color: var(--grey-font-color);
          cursor: pointer;
          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }

    &.block-odd {
      table tbody {
        background-color: var(--table-odd-background);
        &.empty-row {
          background-color: transparent;
        }
        tr:nth-of-type(odd) {
          td.compact {
            filter: brightness(120%);
          }
        }
      }
    }
    &.block-even {
      table tbody {
        background-color: var(--table-even-background);
        &.empty-row {
          background-color: transparent;
        }
        tr:nth-of-type(odd) {
          td.compact {
            filter: brightness(120%);
          }
        }
      }
    }

    table {
      table-layout: fixed;
      color: var(--default-font-color);

      thead tr:nth-child(1) th {
        background-color: var(--marketsheet-headers);
        position: sticky;
        top: 30px;
        z-index: 10;
        border: 0px;
      }

      thead {
        tr {
          &:nth-of-type(odd) {
            background-color: inherit;
          }

          &:nth-of-type(even) {
            background-color: inherit;
          }

          &.table-active {
            color: $primary-color;
            background-color: $secondary-color;
          }
          &:hover {
            background-color: var(--expiry-table-bg);
          }
          td.order.price .cell-symbol {
            color: var(--grey-font-color);
          }
          &.expanded-row {
            &:hover tbody {
              background-color: var(--expiry-table-bg);
            }
          }
          td.trade label.entry {
            &.red.bottom {
              color: $font-red-dark;
            }
            &.green.top {
              color: $font-green-dark;
            }
          }
        }

        tr th {
          text-align: center;
          vertical-align: middle;
          min-width: 60px;
          &.column label.entry {
            margin-bottom: 0px;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 110%;
            width: 90%;
            height: 100%;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &.column.bp label.entry {
            background-color: var(--bid-overlay-background);
          }

          &.column.ap label.entry {
            background-color: var(--ask-overlay-background);
          }
        }
      }
      tbody tr td {
        text-align: center;

        &.order {
          &.price {
            &.bid {
              label.entry {
                background-color: var(--bid-overlay-background);
              }
            }
            &.ask {
              label.entry {
                background-color: var(--ask-overlay-background);
              }
            }
            label.entry {
              font-size: $font-size-prices;
            }
          }
        }

        &.ask,
        &.bid {
          & label {
            cursor: pointer;
          }
          cursor: pointer;
          .cell-symbol {
            font-size: 90%;
            margin-left: 2px;
            color: var(--disabled-color);
          }
        }

        &.trade {
          .oi {
            font-size: $font-size-verysmall;
          }
        }
        &.inside-info {
          background-color: var(
            --inside-informations-background
          ) !important; // important cuz of odd rows in stripped table
        }

        &.not-interactive {
          background-color: var(
            --not-interactive-background
          ) !important; // important cuz not-interacive has highest color prio

          table.inner td {
            background-color: var(
              --not-interactive-background
            ) !important; // important cuz not-interacive has highest color prio
          }
        }

        label.td-entry {
          width: 90%;
        }

        .entry {
          width: 90%;
          height: 100%;
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: $padding-non-aon-entry;
          &.entry-update {
            background-color: var(--order-highlight-background) !important;
            // animation-name: highlight;
            // animation-duration: 1s;
          }
          &.counterparty {
            justify-content: flex-start;
            padding-right: 0;
            padding-left: 5%;
          }
        }

        &.aon-order {
          .entry {
            padding-right: $padding-default;
          }
        }

        label {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        .layer {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          &.suspended {
            background-color: var(--order-suspended-background);
          }

          &.readonly {
            background-color: var(--order-readonly-background);
          }

          &.own-order {
            background-color: var(--order-own-background);
          }

          &.non-own-order {
            background-color: var(--order-background);
          }

          &.quote-request {
            background-color: var(--quote-request-background);
          }

          &.preview-order {
            background-color: var(--order-preview-background);
          }

          &.implied {
            background-color: var(--implied-background);
          }
          &.periodType-quarter_hour {
            background-color: var(--quarter_hour-background);
          }

          &.periodType-half_hour {
            background-color: var(--half_hour-background);
          }

          &.periodType-hour {
            background-color: var(--hour-background);
          }

          &.periodType-block_2_h {
            background-color: var(--block_2_h-background);
          }

          &.periodType-block_4_h {
            background-color: var(--block_4_h-background);
          }
          &.routed {
            background-color: var(--routed-background);
          }
        }
        &.not-interactive {
          label.entry {
            cursor: default;
          }
          cursor: default;
        }
      }
      tbody tr td.compact {
        font-size: 100%;
        .entry {
          flex-direction: column;
          justify-content: center;
        }
        &.order {
          &.price {
            label.entry {
              font-size: 100%;
              span {
                line-height: 100%;
              }
            }
          }
        }
        &.trade {
          label.entry {
            font-size: 100%;
            span {
              line-height: 100%;
            }
          }
        }
      }

      &.inner {
        width: 100%;
      }
    }
  }

  .execprice-arrow {
    margin-right: 2px;
    &.red {
      color: red;
    }
    &.gray {
      color: gray;
    }
    &.green {
      color: green;
    }
  }

  .expiry {
    position: sticky;
    left: -$padding-default;
    z-index: 5;
    border-color: white;
    margin-right: 6px;

    thead tr:nth-child(1) th {
      position: sticky;
      top: 30px;
      z-index: 10;
      padding-right: 15px;
      background-color: var(--marketsheet-headers);
    }

    table thead tr th {
      border-top: 0px;
      vertical-align: middle;
      padding-left: 15px;
      label {
        text-transform: uppercase;
        font-weight: 500;
        margin: 0px;
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
    table tbody {
      background-color: var(--expiry-table-bg);
      tr {
        td {
          cursor: pointer;
          padding-right: 20px;
          border: none;
          &.expiryType {
            span {
              font-size: $font-size-verysmall;
            }
            color: var(--grey-font-color);
            padding-left: 15px;
            display: none;
          }
          label {
            margin: 0;
            display: table-header-group;
            display: flex;
            align-items: center;
            height: 100%;
            padding-left: 2px;
            font-size: 120%;
          }

          &.expiry-HOUR {
            font-weight: bold;
          }

          .layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &.expiry-HOUR {
              background-color: var(--hour-background);
            }

            &.expiry-QUARTER_HOUR {
              background-color: var(--quarter_hour-background);
            }

            &.expiry-HALF_HOUR {
              background-color: var(--half_hour-background);
            }

            &.expiry-BLOCK_2_H {
              background-color: var(--block_2_h-background);
            }

            &.expiry-BLOCK_4_H {
              background-color: var(--block_4_h-background);
            }
          }

          .inner {
            tr {
              background-color: transparent !important;

              td {
                background-color: transparent !important;
              }
            }
          }
        }

        td.compact {
          label {
            align-items: start;
          }
        }
      }
    }

    .carret {
      cursor: pointer;
      position: absolute;
      top: $expiry-caret-offset;
      right: $expiry-caret-offset;
      height: $expiry-caret-width;
      width: $expiry-caret-height;

      .bar {
        position: absolute;
        width: $expiry-caret-height;
        height: 2.25px;
        background: $grey-charcoal;
        top: $expiry-caret-height;
        color: var(--default-font-color);

        &:nth-child(1) {
          transform: translateY(-150%);
        }

        &:nth-child(2) {
          transform: translateY(150%);
        }
      }

      &.up {
        .bar {
          &:nth-child(1) {
            transform-origin: left center;
            transform: translateX(55%) translateY(-100%) rotate(45deg);
          }

          &:nth-child(2) {
            transform-origin: left center;
            transform: translateX(190%) translateY(-100%) rotate(135deg);
          }
        }
      }

      &.down {
        margin-top: 0px;

        .bar {
          &:nth-child(1) {
            transform-origin: left center;
            transform: translateX(70%) translateY(-200%) rotate(45deg);
          }

          &:nth-child(2) {
            transform-origin: left center;
            transform: translateX(140%) translateY(0%) rotate(135deg);
          }
        }
      }
    }
  }

  table {
    color: $grey-charcoal;
    width: 100%;

    &.inner {
      tbody {
        tr {
          &:nth-of-type(odd) {
            background-color: transparent !important;
          }

          td {
            padding: 0;
          }
        }
      }
    }

    thead th {
      border-bottom: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
      padding: 0;
      height: $market-row-height;
      min-height: $market-row-height;
      color: var(--default-font-color);
    }

    tbody {
      tr {
        td {
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          border-top: 0;
          padding: 0;
          height: $market-row-height;
          min-height: $market-row-height;
          color: var(--default-font-color);
        }
        td.compact {
          height: $market-row-height-compact;
        }
      }

      &.empty-row {
        tr td {
          border-right-color: transparent;
          border-left-color: transparent;
          background-color: var(--dock-background);
        }
      }
    }

    tbody + tbody {
      border: 0;
    }
  }

  .market-visibility-filter {
    position: fixed;
    top: 30px;
    right: 15px;
    border: 0.75px solid $grey-cloud;
    border-radius: $border-radius;
    background-color: $white;
    z-index: 5;
    overflow: auto;
  }
}

.instrument-w-200 .instrument {
  min-width: 200px;
  max-width: 200px;
}
.instrument-w-200.chartbook .m_chart {
  width: 200px;
}

.instrument-w-250 .instrument {
  min-width: 250px;
  max-width: 250px;
}
.instrument-w-250.chartbook .m_chart {
  width: 250px;
}

.instrument-w-300 .instrument {
  min-width: 300px;
  max-width: 300px;
}
.instrument-w-300.chartbook .m_chart {
  width: 300px;
}

.instrument-w-350.instrument {
  min-width: 350px;
  max-width: 350px;
}
.instrument-w-350.chartbook .m_chart {
  width: 350px;
}

.instrument-w-400 .instrument {
  min-width: 400px;
  max-width: 400px;
}
.instrument-w-400.chartbook .m_chart {
  width: 400px;
}

.instrument-w-500.chartbook .m_chart {
  width: 500px;
}

.instrument-w-600.chartbook .m_chart {
  width: 600px;
}

.instrument-w-700.chartbook .m_chart {
  width: 700px;
}

.instrument-w-800.chartbook .m_chart {
  width: 800px;
}

.instrument-w-default .chartbook .m_chart {
  width: inherit;
}

.favorite__form,
.width-dropdown {
  position: absolute;
  top: 30px;
  right: 0px;
  width: auto;
  max-width: unset;
  overflow-y: auto;
  overflow-x: auto;
  border: var(--list-border);
  padding: 5px;
  background-color: var(--dock-background);
  color: var(--default-font-color);
}

.market {
  &__actions {
    position: fixed;
    top: 6px;
    z-index: 15;
    width: 72px;
    display: flex;
    justify-content: flex-end;
    height: 32px;
    padding-top: 7px;
    padding-right: 5px;
    padding-left: 5px;
    background-color: transparent;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;

      li {
        cursor: pointer;
        margin-left: $margin-default;
        float: right;
      }
    }
  }
  &__actions.chart-actions {
    width: 30px;
  }

  &__filter {
    .block {
      border-right: 0.75px solid $grey-cloud;

      h3 {
        padding: $padding-default;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .form-range {
        font-size: $font-size-normal;
      }
    }

    .block.block-horizontal {
      border-right: 0px;
      height: 100%;

      .horizontal-inputs {
        border-bottom: 0.75px solid $grey-cloud;

        div {
          padding: calc($padding-default / 2);

          &.form-group {
            padding: 0;
            margin: 0;
          }
        }
      }
    }

    .table-filter-block {
      max-width: $table-filter-block-width;

      .form-range,
      .form-check {
        margin-bottom: 0px;

        input[type='checkbox'] + label {
          height: auto;
          min-height: 15px;
        }
      }
    }
  }
}

.m_chart {
  &:not(:nth-last-child(1)) {
    border-right: 1.5px solid $grey-cloud;
  }

  &__header {
    padding: $padding-default;
    color: var(--default-font-color);
    h3 {
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .chart-options {
      width: 100%;
      min-width: $chart-options-min-width;

      label,
      select {
        font-size: $font-size-headline3;
        font-weight: bold;
      }

      label {
        text-transform: capitalize;
        margin-bottom: 0;
      }
    }
  }

  &__filter {
    .chart-filter-block {
      width: 100%;
      padding-bottom: $padding-default;
      padding-top: $padding-default;
      border-bottom: 0.75px solid $grey-cloud;

      label,
      select {
        font-size: $font-size-headline3;
        font-weight: bold;
      }

      label {
        text-transform: capitalize;
        margin-bottom: 0;
      }

      select {
        margin-top: 4px;
      }
    }

    .chart-filter-block:last-child {
      border: 0px;
    }
  }
}

.icon-popover {
  position: fixed;
  top: 30px;
  right: 0px;
  width: max-content;
  max-width: unset;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: $border-radius;
  padding: 5px;
  z-index: 1;
  max-height: calc(100% - 30px);
  padding-right: 15px;
  &.left-border {
    left: 0px;
    right: auto;
  }
  background-color: var(--table-odd-background);
  color: var(--default-font-color);
  border: var(--list-border);
}
.backdrop-popover {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  cursor: pointer;
}

.market-popup {
  position: absolute;
  width: max-content;
  max-width: unset;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: $border-radius;
  top: 50%;
  left: 50%;
  min-width: 340px;
  padding: 5px !important;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 60px);
  padding-right: 15px;
  z-index: 500;
  display: none;
  &.visible {
    display: block;
  }
  background-color: var(--table-odd-background);
  color: var(--default-font-color);
  border: var(--list-border);
  div.subtitle {
    display: inline-block;
  }
  .popover-close {
    z-index: 2;
    position: relative;
    button {
      float: right;
      border: none;
      background: transparent;
      cursor: pointer
    }
  }
  .instruments__dropdown {
    position: initial;
  }
  .instruments__results ul {
    max-height: 340px;
  }
}
.market-filter-popover {
  position: absolute;
  width: max-content;
  max-width: unset;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: $border-radius;
  padding: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 60px);
  padding-right: 15px;
  z-index: 99;
  &.left-border {
    left: 0px;
    right: auto;
  }
  background-color: var(--table-odd-background);
  color: var(--default-font-color);
  border: var(--list-border);
}

.chart-filter-popover {
  max-width: initial;
}

.expiry-popover {
  position: fixed;
  border: var(--list-border);
}

.expiry-popover {
  padding: $padding-default;
  margin-left: 0.5rem;
  width: 140px;
  border: 1px solid var(--border-color);
  .btn-group-toggle {
    border: var(--list-border);
    height: 25px;
    margin-top: 5px;
    label {
      background-color: transparent;
      color: $white;
      padding-top: 0.75px;

      &.plus {
        width: 25px;
      }
      &.minus {
        margin-right: 2px;
        width: 25px;
      }
      &.minus.active {
        background-color: var(--dock-background);
      }

      &.plus.active {
        background-color: var(--dock-background);
      }
    }
    .plus.active:hover,
    .minus.active:hover {
      background-color: darken($grey-cloud, 10);
    }
  }
  .d-flex {
    margin-top: 5px;
  }
  .arrow {
    left: calc((0.5rem - 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
    position: absolute;
    display: block;
    color: var(--dock-background);
  }
  .arrow:after,
  .arrow:before {
    position: absolute;
    display: block;
    content: '';
    border-color: transparent;
    border-style: solid;
    border-right-color: var(--border-color);
  }
  .arrow:after {
    left: 1px;
  }
  .arrow:before {
    left: 0;
  }
}

#market-context-menu,
.expiry-popover {
  background: var(--table-odd-background);
  border-radius: $border-radius;
  width: $context-menu-width;
  z-index: 99;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding: $padding-default;
      cursor: pointer;

      &:not(:nth-child(1)) {
        border-top: var(--list-border);
      }

      &.disabled {
        color: $grey-iron;
        cursor: default;
        background-color: var(--disabled-color);
      }
    }
  }
}

#market-context-menu {
  background-color: var(--table-odd-background);
  color: var(--default-font-color);
  li {
    &:hover {
      background-color: var(--hover-color);
    }
  }
  position: fixed;
  min-width: 150px;
  border: var(--list-border);
  font-size: $font-size-normal;
}

.sidebar__recent-actions #market-context-menu {
  position: absolute;
}

.dropdown__menu {
  width: auto;

  ul {
    display: block;
    li {
      padding: calc($padding-default / 2);
      line-height: $font-size-normal;
      display: block;
      float: none;
      margin-left: 0px;
      min-width: 150px;
      color: var(--default-font-color);
      &.disabled {
        cursor: default;
        color: var(--disabled-color);
      }
      &:hover {
        background-color: var(--hover-color);
      }
      &:not(:nth-child(1)) {
        border-top: var(--list-border);
      }
    }
  }
}

.react-tags {
  position: relative;
  padding: 0;
  border: 0.75px solid $grey-cloud;
  border-radius: $border-radius;
  font-size: $font-size-normal;
  width: 100%;
  cursor: text;
}

.react-tags.is-focused {
  border-color: $grey-cloud;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 $margin-default $margin-default 0;
  border: var(--list-border);
  border-radius: $border-radius;
  background: $primary-color;
  font-size: $font-size-small;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: var(--default-font-color);
  margin-left: 6px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: $grey-opacity;
}

.react-tags__search {
  display: inline-block;
  padding: $padding-default;
  max-width: 100%;
}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
  background-color: transparent;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
}

.react-tags__suggestions ul {
  margin: 0;
  padding: 0;
  list-style: none;
  background: var(--dock-background);
  border: 0.75px solid $grey-cloud;
  border-radius: $border-radius;
}

.react-tags__suggestions li {
  border-bottom: 0.75px solid $grey-cloud;
  padding: $padding-default;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: bold;
  color: var(--default-font-color);
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: $grey-opacity;
}

.react-tags__suggestions li.is-active {
  background: $grey-opacity;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.popover.fade {
  transition: none;
}

@keyframes highlight {
  from {
    background-color: var(--order-highlight-background);
  }

  to {
    background-color: transparent;
  }
}

.hover-primary:hover {
  color: $primary-color !important;
}

.diviner {
  display: block;
  border-top: 0.75px solid $grey-cloud;
}

.hidden-headlines {
  //first row of expiry table needs to be invisible but as placeholder
  .empty-row:not(:first-child),
  .empty-row:first-child span {
    display: none;
  }
}
.dropdown-list {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  div {
    border: var(--list-border);
    border-radius: $border-radius;
    padding: $padding-default;

    &:hover {
      background-color: $grey-opacity;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 99px;
    font-size: $font-size-normal;
    position: absolute;
    z-index: 11;
    overflow-y: auto;
    width: 100%;
    color: var(--default-font-color);
    background-color: var(--dock-background);

    li {
      cursor: pointer;
      padding: $padding-default;
      white-space: nowrap;
      &:hover {
        background-color: var(--table-even-background);
      }
      &.selected {
        background-color: $grey-opacity;
      }
      &.additional-item {
        color: $grey-cloud;
        font-style: italic;
      }
    }

    .blocked {
      cursor: not-allowed;
    }
  }
  &.visible {
    vertical-align: top;
    ul {
      position: relative;
    }
  }

  input {
    width: 100%;
    background-color: transparent;
  }
}

#quoteRequestForm .form-control,
#priceAlarmForm .form-control {
  background-color: transparent;
  color: var(--default-font-color);
  border-bottom: 0.75px solid $primary-color;
  border-width: 0px 0px 0.75px 0px;
  option {
    background-color: var(--table-odd-background);
  }
}

.dragger-item,
.dragger-disabled {
  .oi {
    color: var(--default-font-color);
  }
}

.sidebar__recent-actions .uitable table,
.theme-light .sidebar__recent-actions .uitable table {
  tbody {
    tr:nth-of-type(odd),
    tr:nth-of-type(even) {
      background-color: var(--table-odd-background);
      color: var(--default-font-color);
    }
  }
}

.form-control:focus {
  background-color: transparent;
  color: var(--default-font-color);
}

div.implied-tooltip {
  background: var(--table-odd-background);
  border-radius: $border-radius;
  min-width: 600px;
  z-index: 99;
  position: fixed;
  padding: 4px;

  table {
    thead {
      tr:nth-child(1) th {
        background-color: var(--marketsheet-headers);
        top: 30px;
        z-index: 10;
        border: 0px;
        padding: 5px 5px;
        text-align: left;
        font-weight: bold;
      }
    }

    tbody {
      tr {
        .implied-table {
          .implied td {
            color: var(--implied-font);
          }
        }

        td {
          text-align: left;
          padding-right: 5px;
          &.implied-contract {
            width: 270px;
          }
        }
      }
    }
  }
}
.instruments__input {
  background-color: var(--dock-background);
  border-radius: 4px;
  padding: 7.5px;
  font-size: 13.5px;
  height: auto;
  border: none;
  border-bottom: 0.75px solid var(--primary-color);
}

.instruments__item {
  padding: 7.5px;
  border-bottom: var(--list-border);
  cursor: pointer;
  &:hover {
    background-color: var(--hover-color);
  }
  &.blocked {
    cursor: not-allowed;
    & > .oi {
      color: var(--disabled-color);
    }
  }
}

ul.ui-status-light {
  list-style: none;
  li {
    float: left;
    margin-right: 15px;
    span {
      margin-right: 5px;
    }
  }
}
