/* Contract Lookup Dialog */
.ciq-lookup-results cq-item,
cq-lookup-results cq-item {
  white-space: nowrap;
}
.ciq-lookup-results cq-item span:nth-of-type(1),
cq-lookup-results cq-item span:nth-of-type(1) {
  /* Search results Symbol */
  width: 100%;
}
.ciq-lookup-results cq-item span:nth-of-type(2),
cq-lookup-results cq-item span:nth-of-type(2) {
  /* Search results Company */
  width: 0%;
}

/* Heads-up to the right */
cq-hu-static {
  right: 0px !important;
  left: auto;
}

/* Font title*/
cq-chart-title cq-symbol {
  font-size: 18px;
  vertical-align: middle;
}
cq-chart-title cq-chart-price {
  vertical-align: middle;
}

/* Layout Studies */
cq-study-legend cq-item .ciq-icon.ciq-close {
  position: absolute;
  right: 35px;
}

/* COLORS */
.ciq-night .ciq-nav,
.ciq-night .sidenav {
  border: 1px solid #262525;
  background: #262525;
  color: #ffffff;
}
.ciq-nav,
.sidenav {
  border: 1px solid #e5e5e5;
  /*background: #e5e5e5;*/
  color: #000000;
}
.ciq-night .ciq-footer {
  border-top: 1px solid #262525;
  background: #262525;
  color: #ffffff;
}
.ciq-footer {
  border-top: 1px solid #e5e5e5;
  /*background: #e5e5e5;*/
  color: #000000;
}
cq-show-range > div:active {
  color: #000000;
  border-top: solid 3px #f17f09;
}
.break-sm .ciq-nav .ciq-menu.stxMenuActive,
.break-md .ciq-nav .ciq-menu.stxMenuActive:not(.toggle-options),
cq-toggle.active,
cq-clickable[role='button'].active,
.sidenav cq-toggle.active {
  border-bottom: solid 3px #f17f09;
}
.ciq-night .chartContainer,
.ciq-night cq-chart-title {
  background: #262525;
  color: #ffffff;
}
.chartContainer,
cq-chart-title {
  /*background: #e5e5e5;*/
  color: #000000;
}
.ciq-night .chartSize,
.ciq-night .stx_notification_tray,
.ciq-night .stx_jump_today {
  background: #262525;
  opacity: 0.8;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
}
.chartSize,
.stx_notification_tray,
.stx_jump_today {
  background: #e5e5e5;
  opacity: 0.8;
  -webkit-box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.3);
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.3);
}
.ciq-night .stx_grid,
.ciq-night .stx_grid_dark {
  color: #424242;
}
.stx_grid,
.stx_grid_dark {
  color: #d5d5d5;
}
.chartSize .stx-chart-control-button.active {
  border-bottom: solid 3px #f17f09;
  height: 22px;
  margin-bottom: 0;
}
.ciq-night:not(.break-sm) cq-hu-static,
.ciq-night .stx-holder cq-study-legend {
  background: #262525;
  opacity: 0.8;
}
:not(.break-sm) cq-hu-static,
.stx-holder cq-study-legend {
  background: #e5e5e5;
  opacity: 0.8;
}
.ciq-active > .ciq-switch:before,
.ciq-switch:checked,
.ciq-active:hover > .ciq-switch:before,
cq-context.ciq-night input:hover,
[cq-context].ciq-night input:hover,
cq-dialog.ciq-night input:hover,
.ciq-night .share-copy .share-link-div:hover,
.ciq-night .ciq-select:hover,
html:not([ciq-last-interaction='touch']) .ciq-night cq-item:hover,
html:not([ciq-last-interaction='touch']) .ciq-night cq-item:focus,
html[ciq-last-interaction='touch'] .ciq-night cq-item:active,
.ciq-night cq-item[cq-focused],
html:not([ciq-last-interaction='touch']) .ciq-night cq-menu cq-item:hover,
html[ciq-last-interaction='touch'] .ciq-night cq-menu cq-item:active,
.ciq-night cq-menu cq-item[cq-focused],
html:not([ciq-last-interaction='touch']) .ciq-night .ciq-context-menu div:hover,
.ciq-night .ciq-select:hover,
cq-context[cq-hide-menu-periodicity].ciq-night
  cq-chart-title
  cq-menu.ciq-menu.ciq-period:hover:not(.stxMenuActive),
cq-context[cq-hide-menu-periodicity].ciq-night
  cq-chart-title
  cq-clickable
  cq-symbol:hover:not(.stxMenuActive),
.ciq-night .ciq-data-table-container label:hover,
.ciq-night .ciq-data-table-container tr:hover,
cq-context input:hover,
[cq-context] input:hover,
cq-dialog input:hover,
.share-copy .share-link-div:hover,
.ciq-select:hover,
html:not([ciq-last-interaction='touch']) cq-item:hover,
html:not([ciq-last-interaction='touch']) cq-item:focus,
html[ciq-last-interaction='touch'] cq-item:active,
cq-item[cq-focused],
html:not([ciq-last-interaction='touch']) cq-menu cq-item:hover,
html[ciq-last-interaction='touch'] cq-menu cq-item:active,
cq-menu cq-item[cq-focused],
html:not([ciq-last-interaction='touch']) .ciq-context-menu div:hover,
.ciq-select:hover,
cq-context[cq-hide-menu-periodicity]
  cq-chart-title
  cq-menu.ciq-menu.ciq-period:hover:not(.stxMenuActive),
cq-context[cq-hide-menu-periodicity]
  cq-chart-title
  cq-clickable
  cq-symbol:hover:not(.stxMenuActive),
.ciq-data-table-container label:hover,
.ciq-data-table-container tr:hover {
  background-color: #f17f09;
}
.ciq-night .stx_chart_controls .stx-tooltip,
.ciq-night cq-toggle cq-tooltip,
.ciq-night cq-clickable cq-tooltip,
.ciq-night div[cq-toolbar-action] cq-tooltip,
.ciq-night [cq-tooltip-activator] cq-tooltip,
.ciq-night cq-undo-section .ciq-icon-btn cq-tooltip,
.ciq-night cq-drawing-settings .ciq-icon-btn cq-tooltip,
.ciq-night cq-drawing-palette .ciq-tool label,
.ciq-night cq-drawing-palette .ciq-mini-widget label {
  background: #424242;
  border: solid 1px #b8b8b8;
  border-radius: 6px;
  color: #ffffff;
}
.stx_chart_controls .stx-tooltip,
cq-toggle cq-tooltip,
cq-clickable cq-tooltip,
div[cq-toolbar-action] cq-tooltip,
[cq-tooltip-activator] cq-tooltip,
cq-undo-section .ciq-icon-btn cq-tooltip,
cq-drawing-settings .ciq-icon-btn cq-tooltip,
cq-drawing-palette .ciq-tool label,
cq-drawing-palette .ciq-mini-widget label {
  background: #a7a789;
  border: solid 1px #b8b8b8;
  border-radius: 6px;
  color: #000000;
}
cq-dialog[native-tabbing] :focus {
  outline: solid 2px #f17f09;
  box-shadow:
    0 0 5px white,
    0 0 2px #f17f09;
}
cq-context.ciq-night input,
[cq-context].ciq-night input,
cq-dialog.ciq-night input,
.ciq-night .share-copy .share-link-div {
  border-color: #555653;
  background-color: #424242;
}
cq-context input,
[cq-context] input,
cq-dialog input,
.share-copy .share-link-div {
  border-color: #999a96;
  background-color: #d5d5d5;
}
.ciq-night .ciq-btn,
.ciq-night .ciq-btn-negative,
.ciq-night .annotationCancel,
.ciq-night .annotationSave {
  background-color: #424242;
}
.ciq-btn,
.ciq-btn-negative,
.annotationCancel,
.annotationSave {
  background-color: #d5d5d5;
}
cq-heading,
.ciq-night cq-hu-static > div > *,
cq-hu-static > div > *,
.ciq-btn,
.ciq-btn-negative,
.annotationCancel,
.annotationSave,
.ciq-btn:active,
.ciq-btn-negative:active,
.annotationCancel:active,
.annotationSave:active,
html:not([ciq-last-interaction='touch']) .ciq-btn:hover,
html:not([ciq-last-interaction='touch']) .ciq-btn-negative:hover,
html:not([ciq-last-interaction='touch']) .annotationCancel:hover,
html:not([ciq-last-interaction='touch']) .annotationSave:hover,
cq-study-legend cq-section-dynamic cq-heading {
  color: #f17f09;
}
html:not([ciq-last-interaction='touch']) .ciq-night cq-lookup-input:hover input,
.ciq-night input[type='search'] {
  background: #424242;
}
html:not([ciq-last-interaction='touch']) cq-lookup-input:hover input,
input[type='search'] {
  background: #d5d5d5;
}
.ciq-night,
.ciq-night cq-drawing-palette,
.ciq-night cq-menu-dropdown,
cq-menu-dropdown.ciq-night,
.ciq-night cq-lookup-results,
cq-lookup-results.ciq-night,
.ciq-night cq-drawing-settings,
.ciq-night cq-study-legend cq-section-dynamic {
  background: #000000;
}
cq-drawing-palette,
cq-menu-dropdown,
cq-lookup-results,
cq-drawing-settings,
cq-study-legend cq-section-dynamic {
  background: #f5f5f5;
  color: #000000;
}
.theme-dark cq-study-legend cq-section-dynamic {
  color: #ffffff;
}
cq-drawing-palette
  .mini-widget-group.mini-widget-foot
  .ciq-mini-widget.ciq-magnet.strong {
  border-bottom: solid 1px #f17f09;
}
.ciq-active .ciq-radio span:after,
.ciq-radio.ciq-active span:after,
html:not([ciq-last-interaction='touch'])
  .ciq-night
  cq-study-legend[cq-hovershow]
  > cq-item:hover,
html:not([ciq-last-interaction='touch'])
  .ciq-night
  cq-study-legend[cq-hovershow]
  cq-comparison-item:hover,
.ciq-night cq-hu-dynamic cq-volume-visual,
html:not([ciq-last-interaction='touch'])
  cq-study-legend[cq-hovershow]
  > cq-item:hover,
html:not([ciq-last-interaction='touch'])
  cq-study-legend[cq-hovershow]
  cq-comparison-item:hover,
cq-hu-dynamic cq-volume-visual {
  background: #f17f09;
}
.ciq-night cq-hu-dynamic cq-volume-grouping > div:nth-of-type(2) {
  background: #606060;
}
cq-hu-dynamic cq-volume-grouping > div:nth-of-type(2) {
  background: #b5b5b5;
}
.ciq-night cq-hu-dynamic .ciq-hu-bg {
  fill: #424242;
}
cq-hu-dynamic .ciq-hu-bg {
  fill: #d5d5d5;
}
.ciq-night cq-hu-dynamic cq-hu-date,
.ciq-night cq-hu-dynamic cq-volume-grouping > div:nth-of-type(3),
.ciq-night cq-hu-dynamic cq-hu-col2 > *,
.ciq-window-bar,
.ciq-night cq-heading[cq-filter] .searchFilter input[type='search'] {
  color: #ffffff;
}
cq-hu-dynamic cq-hu-date,
cq-hu-dynamic cq-volume-grouping > div:nth-of-type(3),
cq-hu-dynamic cq-hu-col2 > *,
.searchFilter input[type='search'] {
  color: #000000;
}
.ciq-night cq-hu-dynamic cq-volume-grouping > div:nth-of-type(1),
cq-hu-dynamic cq-volume-grouping > div:nth-of-type(1) {
  color: #f17f09;
  border-top: 1px solid #f17f09;
}
.ciq-night .hu-tooltip,
.ciq-night .mSticky {
  border: 2px solid #b8b8b8;
  background-color: rgba(66, 66, 66, 0.75);
}
.hu-tooltip,
.mSticky {
  border: 2px solid #b8b8b8;
  background-color: rgba(167, 167, 137, 0.75);
}
.ciq-night .ciq-data-table-container {
  background-color: rgba(0, 0, 0, 0.95);
}
.ciq-data-table-container {
  background-color: rgba(255, 255, 255, 0.95);
}
.ciq-night .ciq-data-table-container button {
  background-color: #424242;
  border-color: #b8b8b8;
}
.ciq-data-table-container button {
  background-color: #d5d5d5;
  border-color: #b8b8b8;
}
cq-marker-label:after {
  background-image: url('stx-sprite-ui.svg');
}
cq-drawing-palette.list
  .mini-widget-group
  .ciq-mini-widget[cq-view='list']
  .icon,
cq-drawing-palette.grid
  .mini-widget-group
  .ciq-mini-widget[cq-view='grid']
  .icon,
cq-drawing-palette .ciq-mini-widget.active .icon,
cq-drawing-palette .ciq-mini-widget:active .icon {
  background-image: url('stx-sprite-palette-miniwidgets-orange.svg');
}
cq-drawing-palette .ciq-tool.active .icon,
cq-drawing-palette .ciq-tool:active .icon,
cq-drawing-palette .ciq-tool[cq-tool-group*='favorite'] .fav-marker,
cq-drawing-settings .ciq-icon-btn.cq-icon-gear.active .icon {
  background-image: url('stx-sprite-drawing-tools-orange.svg');
}
.ciq-night cq-chartcontrol-group {
  background-color: rgba(38, 37, 37, 0.75);
}

.stx-panel-legend {
  height: 100%;
  width: 100%;
}

cq-chartcontrol-group {
  background-color: rgba(229, 229, 229, 0.75);
}
/* cq-context .ciq-chart-area,
*[cq-context] .ciq-chart-area {
  outline: solid 2px #424242;
} */
cq-heading[cq-filter] .searchFilter input[type='search']:focus {
  border-bottom-color: #f17f09;
}
.ciq-window {
  background-color: #424242;
  color: #ffffff;
}
html:not([ciq-last-interaction='touch']) .ciq-night cq-toggle:hover > span,
html:not([ciq-last-interaction='touch'])
  .ciq-night
  cq-clickable[role='button']:hover
  > span,
.ciq-night cq-toggle:active > span,
html:not([ciq-last-interaction='touch'])
  .break-sm
  .ciq-night
  .ciq-menu.collapse:hover
  > span,
.break-sm .ciq-night .ciq-menu.collapse:active > span,
.ciq-night .ciq-menu.toggle-options:hover {
  border: solid 1px #424242;
}
html:not([ciq-last-interaction='touch']) cq-toggle:hover > span,
html:not([ciq-last-interaction='touch'])
  cq-clickable[role='button']:hover
  > span,
cq-toggle:active > span,
html:not([ciq-last-interaction='touch'])
  .break-sm
  .ciq-menu.collapse:hover
  > span,
.break-sm .ciq-menu.collapse:active > span,
.ciq-menu.toggle-options:hover {
  border: solid 1px #d5d5d5;
}
cq-hu-static > div > div {
  color: #000000;
}
.ciq-night cq-hu-static > div > div {
  color: #ffffff;
}
.stx_range_slider.shading {
  border: solid 2px #f17f09;
}

cq-context,
[cq-context] {
  height: calc(100% - 39px);
  width: calc(100%);
}

cq-chart-instructions {
  height: 0px;
  display: block;
}

cq-chart-instructions p,
cq-chart-instructions span {
  font-size: 0;
}

cq-dialog {
  top: 20%;
  margin-top: 0px;
  z-index: 15;
}

.trade-toggles.ciq-toggles {
  display: none;
}

.ciq-dropdowns cq-menu.collapse:not(.show) {
  display: block;
}

.full-screen-show cq-clickable {
  margin-top: 0px;
}
div.cq-dialogs ~ .ciq-night,  .ciq-night:has(~ div.cq-dialogs) {
  z-index: 30;
}

cq-chart-title.stx-show {
  display: none;
}
.full-screen cq-chart-title.stx-show {
  display: block;
}

.chartSize span.stx-zoom-out,
.chartSize span.stx-zoom-in, 
.chartSize span.stx-full-screen {
  display: none;
}

.ciq-night cq-attrib-container {
  background: transparent;
}

