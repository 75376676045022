
.contracts__results ul li {
    padding: 7.5px;
    border-bottom: var(--list-border);
}

.contracts__results { // check contracts__results not overridden by same from orderForm
    display: block;
}
    .instruments, .contracts {
        position: relative;
        user-select: none;
        width: 100%;

        &__search {
            input[type=text] {
                &.form-control {
                    background-color: var(--dock-background);
                    border-radius: $border-radius;
                    padding: $padding-default;
                    font-size: $font-size-normal;
                    height: auto;
                }

                &.active {
                    color: var(--default-font-color);
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        &__dropdown {
            position: absolute;
            display: none;
            font-size: $font-size-normal;
            background-color: var(--dock-background);
            border-bottom: var(--list-border);
            color: var(--defult-font-color);
            &.visible {
                display: block;
            }

            width: 100%;
            z-index: 1;
            border-bottom-right-radius: $border-radius;
            border-bottom-left-radius: $border-radius;

            .type-trigger {
                position: relative;
                display: flex !important;
                background-color: transparent;
                border-bottom-right-radius: $border-radius;
                border-bottom-left-radius: $border-radius;

                padding-top: $padding-default;

                .form-group {
                    margin-bottom: 0.1875rem;

                    &.form-check:first-child {
                        padding: 0;
                        margin-left: 0.5625rem;

                        .form-check-input {
                            margin-left: 0.375rem;
                        }
                    }

                    &.form-check:nth-child(2) {
                        padding: 0;
                        margin-left: 1.875rem;

                        .form-check-input {
                            margin-left: 0.375rem;
                        }
                    }
                }
            }
        }

        &__results {
            position: relative;
            overflow-y: auto;
            background-color: transparent;
            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                height: auto;
                max-height: 180px;
                font-size: $font-size-normal;
                color: var(--default-font-color);
                li {
                    cursor: move;
                    padding: $padding-default;
                    border-bottom: var(--list-border);
                    &:hover {
                        background-color: var(--hover-color);
                    }
                    &.blocked .oi {
                        color: var(--disabled-color);
                    }
                }
                li.pointer-cursor {
                    cursor: pointer;
                }

                .blocked {
                    cursor: not-allowed;
                }
            }
        }

        .content-switch {
            text-align: center;

            .content-switch__item {
                display: inline-block;
                margin: 3px 30px;
                cursor: pointer;
                &.active {
                    font-weight: bold;
                }
            }
        }

        .venues__results ul {
            padding-left: 0px;
            li {
                list-style: none;
            }
        }

    }
