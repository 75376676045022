@font-face {
  font-family: 'DINOT';
  src: url('../assets/fonts/DINOT.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT-Bold';
  src: url('../assets/fonts/DINOT-Bold.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT-BoldItalic';
  src: url('../assets/fonts/DINOT-BoldItalic.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT-Italic';
  src: url('../assets/fonts/DINOT-Italic.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT-Medium';
  src: url('../assets/fonts/DINOT-Medium.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT-MediumItalic';
  src: url('../assets/fonts/DINOT-MediumItalic.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Icons';
  src: url('../assets/fonts/open-iconic.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../assets/fonts/Roboto-Bold.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Italic';
  src: url('../assets/fonts/Roboto-Italic.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('../assets/fonts/Roboto-BoldItalic.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('../assets/fonts/Roboto-Black.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url('../assets/fonts/Roboto-BlackItalic.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url('../assets/fonts/Roboto-Italic.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../assets/fonts/Roboto-Medium.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url('../assets/fonts/Roboto-MediumItalic.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url('../assets/fonts/Roboto-Thin.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url('../assets/fonts/Roboto-ThinItalic.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('../assets/fonts/Roboto-Light.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('../assets/fonts/Roboto-LightItalic.woff2');
  font-weight: normal;
  font-style: normal;
}