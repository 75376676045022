.contracts {
    position: relative;
    width: 100%;
    &__search {
        input[type=text] {
            &.form-control {
                background-color: $white;
                border-radius: 3.75px;
                color: $grey-iron;
            }
        }
    }

    &__results {
        position: relative;
        display: none;
        width: 100%;
        background-color: $white;
        overflow-y: auto;
        border-radius: 3.75px;

        &.visible {
            display: block;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            color: $grey-iron;
            height: auto;
            max-height: 150px;
            font-size: 12px;

            li {
                cursor: pointer;

                &:hover {
                    background-color: $grey-opacity;
                }
            }
        }
    }
}
#orderForm {
    .modal-dialog {
        max-width: 560px;
    }
    .modal-footer {
        border: none;
        padding: 0;
        padding-top: $padding-modal;
        min-height: $footer-height;
        position: initial;
    }
    .modal-content {
        width: 90%;
        height: initial;

        .form-inline {
            margin-top: $margin-modal;
            margin-left: $margin-default;
            margin-right: $margin-default;
            border: none;
            justify-content: space-between;

            .form-group {
                padding-left: 0;
                label {
                    padding-right: $padding-default;
                }
            }
        }
        label.qtyUnit {
            padding-left: 5px;
        }
        .form-group.restriction,
        .form-group.price,
        .form-group.quantity {
            >input {
                text-align: right;
            }
            padding: calc($padding-default / 2);
            margin-left: $margin-default;
        }
        .form-group.restriction {
            >select.form-control {
                padding: $padding-default;
                text-align: center;
            }
        }
        .orderbook {
            max-height: 380px;
            overflow-y: auto;
            overflow-x: hidden;
            .instrument {
                border: 0px;
                table thead tr:nth-child(1) th {
                    top: 0px;
                }
            }
            p, span, label {
                font-size: $font-size-small;
            }
        }
    }
}
.sanitycheck-warnings {
    li {
        margin-left: 12px;
    }
    span {
        font-weight: bold;
        margin: 5px 0px 5px 0px;
        display: block;
    }
}

.order-sidebar {
    position: absolute;
    top: 0px;
    right: -15px;
    height: 100%;
    overflow-y: auto;
    transition: all 0.3s ease;
    z-index: 6;
    display: block;

    background-color: var(--sidebar-bg);
    color: var(--sidebar-font-color);

    &.hidden {
        display: none;
    }

    .threebar.cross {
        cursor: pointer;
        position: fixed;
        right: 370.5px;
        height: 30px;
        width: 37.5px;
        transition: left 0.3s ease;
        z-index: 4;
        background-color: var(--sidebar-bg);
        border-top-left-radius: 7.5px;
        border-bottom-left-radius: 7.5px;
        color: var(--sidebar-font-color);
        .bar {
            &:nth-child(1) {
            transform: rotate(45deg);
            }

            &:nth-child(2) {
            opacity: 0;
            }

            &:nth-child(3) {
            transform: rotate(-45deg);
            }
            position: absolute;
            width: 22.5px;
            height: 3.75px;
            background: var(--sidebar-font-color);
            top: 12.75px;
            transition: all 0.3s ease;
            margin-left: 7.5px;
        }
    }

    #orderForm {
        padding: 24px 16px 24px 12px;

        h3 {
            font-size: 20px;
        }

        form {
            margin-top: 45px;
            h4 {
                font-size: 16px;
            }

            .form-group label {
                width: 105px;
                font-size: $font-size-normal;
                font-weight: bold;
            }

            .form-control {
                background-color: var(--dock-background);
                color: var(--default-font-color);

                display: inline-block;
                border-radius: 0%;
                border: none;
                
                height: 40px;
                padding: 8px;

                option {
                    width: 160px;
                    background-color: var(--dock-background);
                    cursor: pointer;
                    &:hover {
                        background-color: var(--table-odd-background);
                    }
                }
                .orderbook-preview {
                    .instrument {
                        table {
                            background-color: var(--table-odd-background);
                        }
                    }
                }
            }
            input.form-control  {
                width: 120px;
                text-align: right;
            }
            select.form-control  {
                width: 160px;
            }
            .input-additional {
                color: var(--grey-font-color);
                background-color: var(--dock-background);
                display: inline-block;
                margin: 0px;
                text-align: center;
                min-width: 40px;
                padding: 10px 4px 10px 4px;
                height: 40px;
            }
        }
        .number-spinner .spinner-vertical {
            display: none;
        }

        .instrument th label.entry span {
            font-size: $font-size-verysmall;
        }
        .instrument td label.entry span {
            font-size: $font-size-verysmall;
        }

        .orderbook-preview {
            margin-top: 50px;

            .instrument {
                table {
                    background-color: var(--table-odd-background);
                    tbody tr td.order.price label.entry {
                        font-size: $font-size-small;
                    }
                    tbody tr td label.entry {
                        font-size: $font-size-verysmall;
                    }
                }
                h4 {
                    margin-bottom: 10px;
                }
            }
        }

        .form-group:nth-child(1) {
            margin-top: 50px;
        }
        .contract-selection {
            margin-bottom: 10px;
            h4 {
                margin-bottom: 10px;
            }
        }
    }
    .modal-footer {
        margin-top: 50px;
        justify-content: center;
        background-color: var(--sidebar-bg);
        color: var(--sidebar-font-color);
        button {
            width: 132px;
            height: 40px;
            border-radius: 0%;
            font-size: $font-size-headline3;
            color: var(--sidebar-font-color);
            &.btn-approval {
                background-color: $primary-color;
                color: var(--sidebar-font-color);
            }
            &.btn-secondary {
                color: var(--sidebar-font-color);
            }
            &.btn-cancellation  {
                background-color: transparent;
                border: 0.75px solid var(--sidebar-font-color);
                color: var(--sidebar-font-color);
            }
            &.btn:focus {
                box-shadow: 0;
            }
        }
    }
}