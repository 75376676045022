.ag-row {
    border-bottom: none;
}

.ag-cell {
    padding-left: 4px;
    padding-right: 4px;
    line-height: 20px;
}

.table-custom-border .ag-row .ag-cell {
    border-bottom: 0.75px solid var(--border-color-background);
}

.table-custom-border .ag-row .ag-cell.expiry-column {
    border-bottom: 0.75px solid var(--expiry-table-bg);
}

.table-custom-border .ag-row .ag-cell.not-interactive {
    border-bottom: 0.75px solid var(--not-interactive-background);
}

.table-custom-border .ag-row .ag-cell .expiry-column {
    border: 0.75px transparent;
}

.ag-header {
    border: none;
    background-color: var(--dock-background);
}

.ag-header-cell, .ag-header-cell:hover {
    padding-left: 4px;
    padding-right: 4px;
    text-align: center;
    background-color: var(--primary-color) !important;
}

.ag-header-cell-label {
    text-align: center;
}
.ag-right-aligned-cell {
    text-align: right;
}
.ag-header-group-cell-label.header {
    display: flex;
    justify-content: space-between;
}

.ag-pinned-left-header {
    margin-right: 4px;
    margin-top: auto;
    height: 31px;
}
.ag-pinned-left-header .ag-header-cell-label {
    align-items: start;
}
.ag-pinned-left-cols-container {
    margin-right: 4px;
}

.ag-layout-normal {
    background: var(--dock-background);
}
.ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total),
.ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
    border: none;
}

.ag-root-wrapper {
    border: none;
}

.ag-menu, .ag-tabs, .ag-tab-selected {
    background-color: var(--dock-background) !important;
    color: var(--default-font-color);
}

.ag-menu-option-active {
    background-color: var(--primary-color);
    color: var(--default-font-color);
}

