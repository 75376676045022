.home {
  &__container {
    position: relative;

    .docks__wrapper {
      position: relative;
      width: 100%;
      margin: 7.5px 0;
      height: calc(100% - 20px);
    }
    .disconnect-fade {
      background-color: $grey-opacity;
      cursor: wait;
    }
  }
}
.dashboard-panel .flexlayout__tab {
  display: flex;
}
.dashboard-panel .flexlayout__tabset {
  background-color: #272626;
}

.dashboard-panel .flexlayout__tabset-selected {
  background-image: none;
  background-color: #272626;
}

#cancel-drag-area {
  position: fixed;
  width: 3rem;
  height: 3rem;
  bottom: 26.25px;
  left: 26.25px;
  text-align: center;
  z-index: 999;

  .oi {
    font-size: $font-size-headline3;
  }
}

.watermark {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.dashboard-panel {
  padding: 15px;
  height: 100%;
  position: relative;
  &__container {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
  }
  .isLocked {
    width: calc(100% - 330px);
  }
}
