.priceAlarm {
  &__icons {
    &.triggered-true {
      color: red;
    }
    &.triggered-false{
      color: green;
    }
  }
}

#priceAlarmForm {
  .type-toggle {
    .btn {
      border-width: 0px;
      margin-right: 2px;
      width: calc(50% - 2px);
    }
  }

  .react-datepicker {
    width: 328px;
  }

  .gtc-button {
    cursor: pointer;
  }

  .contracts {
    padding-bottom: 10px;
  }

  input[type=text] {
    background-color: var(--dock-background);
  }
}

.btn.create-price-alarm {
  line-height : 0.55rem;
  margin-top: 2px;
}

.uitable.PriceAlarm {
  height: calc(100% - 25px);
}